/* eslint-disable */
import React from "react"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"
import { Icon } from "react-icons-kit"
import { playCircle } from "react-icons-kit/fa/playCircle"
import { openModal, closeModal } from "@redq/reuse-modal"
import Text from "common/components/Text"
import Image from "common/components/Image"
import Button from "common/components/Button"
import Heading from "common/components/Heading"
import Rating from "common/components/Rating"
import Container from "common/components/UI/Container"
import GatsbyImage from "common/components/GatsbyImage"
import BannerWrapper, {
  BannerContent,
  RatingInfo,
  BannerImage,
  ButtonGroup,
  VideoGroup,
  VideoWrapper,
  CustomerWrapper,
  ImageWrapper,
} from "./banner.style"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect"

import microsoft from "common/assets/image/appModern/envato-icon.png"
import videoBanner1 from "common/assets/image/appModern/video-1.png"
import videoBanner2 from "common/assets/image/appModern/video-2.png"
import circleBorder from "common/assets/image/appModern/shape.svg"
import mockup from "common/assets/image/appModern/banner2.png"
import appStore from "common/assets/image/appModern/app store-01.png"
import playStore from "common/assets/image/appModern/play store-02.png"
// close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
)

const ModalContent = () => (
  <VideoWrapper>
    <iframe
      title="Video"
      src="https://www.youtube.com/embed/8ME-QAlW6Ww"
      frameBorder="0"
    />
  </VideoWrapper>
)

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        client {
          id
          title
          image {
            publicURL
          }
        }
      }
    }
  `)
  const { client } = data.appModernJson
  // modal handler
  const handleVideoModal = () => {
    openModal({
      config: {
        className: "video-modal",
        disableDragging: true,
        default: {
          width: "auto",
          height: "auto",
          x: 0,
          y: 0,
        },
      },
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    })
  }
  return (
    <BannerWrapper id="home">
      <Container>
        {/* {isMobile && (
          <BannerImage>
            <Fade up delay={100}>
              <Image
                style={{ width: isMobile ? "110vw" : "" }}
                src={mockup}
                alt="Banner"
              />
            </Fade>
          </BannerImage>
        )} */}
        <BannerContent>
          {/* <Fade up>
            <RatingInfo>
              <Rating rating={4} />
              4.9 of 5 By <img src={microsoft} alt="Microsoft" />
            </RatingInfo>
          </Fade> */}
          <Fade up delay={100}>
            <Heading
              as="h1"
              content="All Your Vehicle Solutions In One Place."
            />
          </Fade>
          <Fade up delay={200}>
            <Text content="Someone is blocking your car and you can’t get it out? Having an emergency? DAYRA will provide you with all the solutions for all your problems. Download the app and enjoy all of our services now." />
          </Fade>
          <Fade up delay={300}>
            <ButtonGroup style={{ marginBottom: isMobile && "10vh" }}>
              <img
                src={appStore}
                alt=""
                style={{ width: "200px", boxShadow: "black" }}
              />
              <img src={playStore} alt="" style={{ width: "200px" }} />
            </ButtonGroup>
          </Fade>
          {/* <VideoGroup>
            <img
              src={videoBanner1}
              onClick={handleVideoModal}
              alt="Microsoft"
            />
            <img
              src={videoBanner2}
              onClick={handleVideoModal}
              alt="Microsoft"
            />
          </VideoGroup> */}
        </BannerContent>
        {!isMobile && (
          <BannerImage>
            <Fade up delay={100}>
              <Image src={mockup} alt="Banner" />
            </Fade>
          </BannerImage>
        )}
      </Container>
      {/* <CustomerWrapper>
        <Text content="Trusted by companies like:" />
        <ImageWrapper>
          {client.map((item) => (
            <Image
              key={`client-key${item.id}`}
              src={item.image.publicURL}
              alt={item.title}
            />
          ))}
        </ImageWrapper>
      </CustomerWrapper> */}
      <img
        className="bannerBottomShape"
        src={circleBorder}
        alt="Bottom Circle"
      />
    </BannerWrapper>
  )
}

export default Banner
