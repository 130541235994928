import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Box from "common/components/Box"
import Text from "common/components/Text"
import Image from "common/components/Image"
import Logo from "common/components/UIElements/Logo"
import Heading from "common/components/Heading"
import Container from "common/components/UI/Container"
import whatsapp from "../../../common/assets/image/appModern/Dayra icons-19.png"
import facebook from "../../../common/assets/image/appModern/Dayra icons-16.png"
import instagram from "../../../common/assets/image/appModern/Dayra icons-15.png"
import tiktok from "../../../common/assets/image/appModern/Dayra icons-18.png"
import snapChat from "../../../common/assets/image/appModern/Dayra icons rest of -29.png"
import ximg from "../../../common/assets/image/appModern/Dayra icons rest of -30.png"
import english from "../../../common/assets/image/appModern/OIP (2).jpeg"
import arabic from "../../../common/assets/image/appModern/Flag_of_Egypt.jpg"
import youtube from "../../../common/assets/image/appModern/youtube icon yellow.png"
import PropTypes from "prop-types"

import { isMobile } from "react-device-detect"
import { Link } from "react-router-dom"
import FooterArea, {
  WidgetArea,
  MenuArea,
  Menu,
  MenuItem,
  CopyrightText,
} from "./footer.style"
import { prototype } from "react-stickynode"

const Footer = ({ lang }) => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        footer {
          logo {
            publicURL
          }
          menu {
            id
            link
            text
          }
          widgets {
            id
            icon {
              publicURL
            }
            title
            description
          }
        }
      }
    }
  `)
  const { logo, menu, widgets } = data.appModernJson.footer
  const date = new Date()
  const today = new Date()
  const year = date.getFullYear()

  return (
    <FooterArea>
      <Container>
        {/* <WidgetArea>
          {widgets.map((item) => (
            <Box className="col" key={`footer-widget--key${item.id}`}>
              <Image src={item.icon.publicURL} alt={item.title} />
              <Heading as="h3" content={item.title} />
              <Text content={item.description} />
            </Box>
          ))}
        </WidgetArea> */}
        {/* End of footer widgets area */}
        <MenuArea>
          <Logo
            className="logo"
            href="/"
            logoSrc={logo.publicURL}
            title="App Classic"
          />
          <Menu>
            {menu.map((item) => (
              <MenuItem key={`footer-link${item.id}`}>
                <a href={item.link}>{item.text}</a>
              </MenuItem>
            ))}
          </Menu>
          <div className="row" style={{ textAlign: "center" }}>
            {/* <div className="col-lg-12 col-md-12 col-sm-12"> */}
            <img src={whatsapp} alt="" style={{ width: "70px" }} href=""></img>
            <a href="https://t.snapchat.com/PtH0VCyb" target="_blank">
              <img src={snapChat} alt="" style={{ width: "70px" }}></img>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61550347221778&mibextid=LQQJ4d"
              target="_blank"
            >
              <img src={facebook} alt="" style={{ width: "70px" }}></img>
            </a>
            <a
              href="https://www.tiktok.com/@dayra_app_eg?_t=8gnlTOeqbv3&_r=1"
              target="_blank"
            >
              <img src={tiktok} alt="" style={{ width: "70px" }}></img>
            </a>
            <a
              href="https://instagram.com/dayraapp?igshid=MzRlODBiNWFlZA=="
              target="_blank"
            >
              <img src={instagram} alt="" style={{ width: "70px" }}></img>
            </a>
            <a href="https://twitter.com/Dayraapp?t=e9LJAFDCNWUlawWqcjC_yw&s=08" target="_blank">
              <img src={ximg} alt="" style={{ width: "70px" }}></img>
            </a>
            <a
              href="https://www.youtube.com/channel/UCTNs1eN7NdvOz0eURZFtycg"
              target="_blank"
            >
              <img src={youtube} alt="" style={{ width: "70px" }}></img>
            </a>
          </div>
          {/* <div style={{ float: "right" }} className="mohsen">
            {lang == "ar" && (
              <a href="/">
                <img
                  src={english}
                  style={{  borderRadius: "50%" }}
                ></img>
              </a>
            )}
            {lang == "en" && (
              <a href="/appmodernAr">
                <img
                  src={arabic}
                  style={{ borderRadius: "50%" }}
                ></img>
              </a>
            )}
          </div> */}
          {/* </div> */}
          <div
            class="col-lg-12 col-md-12 col-sm-12"
            style={{
              textAlign: "center",
              // position: abs ? "absolute" : "",
              bottom: "-5px",
              left: isMobile ? "22%" : "0",
            }}
          >
            <p
              style={{
                margin: "0",
                opacity: "0.5",
                fontSize: "16px",
                lineHeight: "1.8",
                fontWeight: "700",
                fontFamily: "Segoe UI",
                color: "#fff",
              }}
            >
              Copyright &copy; {today.getFullYear()} by{" "}
              <span>
                {" "}
                <a
                  target="_blank"
                  href="https://www.neoneg.com/"
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "#fff",
                    fontWeight: "700",
                  }}
                >
                  NEON
                </a>{" "}
              </span>
              <a
                href=""
                style={{
                  textDecoration: "none",
                  fontWeight: "700",
                  color: "#fff",
                }}
                onClick={() => {
                  window.open(`https://wa.me/+201113588988`)
                }}
              >
                <i
                  className="fa-brands fa-whatsapp fs-5"
                  style={{ color: "#fff", marginRight: "10px" }}
                ></i>
              </a>{" "}
            </p>{" "}
          </div>
        </MenuArea>
        {/* End of footer menu area */}
      </Container>
    </FooterArea>
  )
}
Footer.propTypes = {
  lang: PropTypes.object,
}
export default Footer
